exports.components = {
  "component---src-craft-dynamic-queries-article-index-query-js": () => import("./../../../src/craft/dynamicQueries/ArticleIndexQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-article-index-query-js" */),
  "component---src-craft-dynamic-queries-articles-query-js": () => import("./../../../src/craft/dynamicQueries/ArticlesQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-articles-query-js" */),
  "component---src-craft-dynamic-queries-home-query-js": () => import("./../../../src/craft/dynamicQueries/HomeQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-home-query-js" */),
  "component---src-craft-dynamic-queries-jobs-query-js": () => import("./../../../src/craft/dynamicQueries/JobsQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-jobs-query-js" */),
  "component---src-craft-dynamic-queries-page-query-js": () => import("./../../../src/craft/dynamicQueries/PageQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-page-query-js" */),
  "component---src-craft-dynamic-queries-people-query-js": () => import("./../../../src/craft/dynamicQueries/PeopleQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-people-query-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

